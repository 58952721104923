import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import { connect } from 'react-redux';
import { translations } from '../../../sharedJs/translations';
import EpiLink from '../../links/epiLink/EpiLink';
import NavMenu from '../../navMenu/NavMenu';
import BreadCrumbs from '../../breadCrumbs/BreadCrumbs';
import SvgElement from '../../uiElements/svgElement/SvgElement';
import LoadingSpinner from '../../uiElements/loadingSpinner/LoadingSpinner';
import HeaderSearchField from '../../headerSearchField/HeaderSearchField';
import { bulkSetAttribute } from '../../../sharedJs/utilityFunctions';

import '../../../styles/_utilities.scss';
import '../../../styles/_grid.scss';
import './_header.scss';

const menuIconWhite = '/assets/miljostatus/icons/icon-menu-white.svg';
const mobileMiljostatusLogo = '/assets/miljostatus/logo/logo_miljostatus.svg';
const mobileMiljostatusLogoEnglish = '/assets/miljostatus/logo/logo_miljostatus_engelsk.svg';
const desktopMiljostatusLogo = '/assets/miljostatus/logo/logo_miljostatus.svg';
const desktopMiljostatusLogoEnglish = '/assets/miljostatus/logo/logo_miljostatus_engelsk.svg';
const menuIconBlue = '/assets/miljostatus/icons/icon-menu-blue.svg';
const closeIconBlue = '/assets/miljostatus/icons/icon-close-blue.svg';
const searchIconBlue = '/assets/miljostatus/icons/icon-search-blue.svg';
const closeBlack = '/assets/miljostatus/icons/icon-close-black.svg';

class Header extends Component {
    constructor() {
        super();
        this.state = {
            navMenuIsOpen: false,
            mobileSearchIsOpen: false,
            closeMobileSearch: false
        };
        autoBind(this);
        this.mobileSearchPanelRef = React.createRef();
        this.pathToSearchPage = '/sok/';
    }

    componentDidUpdate(prevProps) {
        if ((this.props.location && prevProps.location) && (this.props.location.pathname !== prevProps.location.pathname)) {
            this.setState({ navMenuIsOpen: false, mobileSearchIsOpen: false, closeMobileSearch: false }); // eslint-disable-line
        }
    }

    getHeaderClassName() {
        let cN = '';
        let cNopen = '';
        let extraClass = '';

        const header = document.getElementById('c_master-header');
        if (header && header.classList) {
            if (header.classList.contains('c_master-header--env-goals')) {
                extraClass = 'c_master-header--env-goals';
            }
        }
        if (this.props.location && this.props.location.pathname === '/') {
            cN = `${this.props.className}--miljostatus-frontpage`;
        }
        if (this.state.navMenuIsOpen) {
            cNopen = `${this.props.className}--nav-open`;
        }
        return `${this.props.className} ${cNopen} ${this.props.className}--miljostatus ${cN} ${extraClass}`;
    }

    getLogo(isDesktop) {
        if (isDesktop) {
            return this.props.language.name === 'en' ? desktopMiljostatusLogoEnglish : desktopMiljostatusLogo;
        }
        return this.props.language.name === 'en' ? mobileMiljostatusLogoEnglish : mobileMiljostatusLogo;
    }

    getIcon(icon) {
        switch (icon) {
        case 'hamburger':
            return menuIconBlue;
        case 'close':
            return closeIconBlue;
        case 'search':
            return searchIconBlue;
        default:
            return null;
        }
    }

    setMobileSearchPanelState(open) {
        const aTags = document.getElementsByTagName('a');
        const buttons = document.getElementsByTagName('button');
        const inputs = document.getElementsByTagName('input');
        const textareas = document.getElementsByTagName('textarea');
        const selectTags = document.getElementsByTagName('select');

        if (open) {
            bulkSetAttribute({
                items: [...aTags, ...buttons, ...inputs, ...textareas, ...selectTags],
                attr: 'tabIndex',
                value: '-1'
            });
            this.setState({ mobileSearchIsOpen: true });
            document.documentElement.style.overflow = 'hidden';
        } else {
            this.setState({ closeMobileSearch: true });
            setTimeout(() => {
                this.setState({ mobileSearchIsOpen: false, closeMobileSearch: false });
                bulkSetAttribute({
                    items: [...aTags, ...buttons, ...inputs, ...textareas, ...selectTags],
                    attr: 'tabIndex',
                    value: '0'
                });
            }, 700);
            document.documentElement.style.overflow = 'scroll';
        }
    }

    focusHandler() {
        if (!this.state.navMenuIsOpen) {
            this.setState({ navMenuIsOpen: true });
        }
    }

    toggleNavMenu() {
        window.scrollTo(0, 0);
        this.setState({
            navMenuIsOpen: !this.state.navMenuIsOpen
        });
    }

    calculateBodyHeight() {
        return document.body.offsetHeight - 117; // offset header height
    }

    renderSuggestions(showOnMobile) {
        if (this.props.searchIsLoading) {
            return (
                <div className={`c_search-field-suggestions${showOnMobile ? ' c_search-field-suggestions--small-screen' : ' u_hidden--mobile'}`}>
                    <ul className="c_search-field-suggestions__list">
                        <li className="c_search-field-suggestions__list-item" key="suggestionKey__loading-spinner">
                            <LoadingSpinner />
                        </li>
                    </ul>
                </div>
            );
        }
        if (this.props.searchSuggestions.length > 0) {
            return (
                <div className={`c_search-field-suggestions${showOnMobile ? ' c_search-field-suggestions--small-screen' : ' u_hidden--mobile'}`}>
                    <ul className="c_search-field-suggestions__list">
                        {this.props.searchSuggestions.map(item => (
                            <li
                                className="c_search-field-suggestions__list-item"
                                key={`suggestionKey__${item.title}_${item.url}`}
                            >
                                <EpiLink url={item.url} forceUpdate>
                                    <span className="name">{item.title}</span>
                                </EpiLink>
                            </li>
                        ))}
                        <li className="c_search-field-suggestions__list-item" key="suggestionKey__toSearchPage">
                            <EpiLink url={`${this.pathToSearchPage}?searchString=${this.props.searchInputValue}`}>
                                <span className="name">{`Vis alle treff på "${this.props.searchInputValue}"`}</span>
                            </EpiLink>
                        </li>
                    </ul>
                </div>
            );
        }
        return null;
    }

    render() {
        const { pathname } = this.props.location;
        let currentLevel = null;
        const homePath = '/';

        const translate = translations(this.props.language.name);
        const {
            header
        } = this.props.epiDataModel;

        if (header && header.menu && header.menu.length > 0) {
            if (pathname.toString() === '/') {
                currentLevel = header.menu.find(item => item.pageName === '');
            } else {
                currentLevel = header.menu.find(item => (item.url.toLowerCase() === pathname.toString().toLowerCase()));
            }
        }

        return (
            <Fragment>
                <header
                    id="c_master-header"
                    className={`${this.getHeaderClassName()} ${pathname === '/' ? `${this.props.className}--front-page` : ''}`}
                >
                    <h1 className="u_visuallyhidden">{translate.App.AppName}</h1>
                    <a
                        href="#content"
                        className="skipLink skipLink--top"
                    >{translate.App.SkipToMainContent}
                    </a>
                    <div className="g_container g_container--full" id="headerLogo">
                        <EpiLink
                            url={homePath}
                            className={`${this.props.className}__home-link`}
                            aria-label={translate.App.BackToFrontPage}
                        >
                            <span className="u_visuallyhidden">{translate.App.BackToFrontPage}</span>
                            <SvgElement
                                src={this.getLogo(false)}
                                title={translate.App.BackToFrontPage}
                                desc={`Logo for Miljøstatus - ${translate.App.GoBackToFrontPage}`}
                                className={`${this.props.className}__logo ${this.props.className}__logo--mobile`}
                            />
                            <SvgElement
                                src={this.getLogo(true)}
                                title={translate.App.BackToFrontPage}
                                desc={`Logo for Miljøstatus - ${translate.App.GoBackToFrontPage}`}
                                className={`${this.props.className}__logo ${this.props.className}__logo--desktop`}
                            />
                        </EpiLink>
                        <div className={`${this.props.className}__content-wrapper`}>
                            <div className={`${this.props.className}__actions-wrapper`}>
                                <button
                                    className="c_mobile-header-search-button u_hidden--desktop"
                                    aria-label={translate.App.Search}
                                    aria-expanded={this.state.mobileSearchIsOpen}
                                    onClick={() => this.setMobileSearchPanelState(true)}
                                >
                                    <span className="u_visuallyhidden">{translate.App.Search}</span>
                                    <SvgElement
                                        src={this.getIcon('search')}
                                        title={translate.App.Search}
                                        desc={translate.App.ClickToSearch}
                                    />
                                </button>
                                {pathname.toString() !== this.props.searchPageUrl && window.innerWidth > 768 &&
                                <HeaderSearchField
                                    className="u_hidden--mobile"
                                    id="mainHeaderSearchInput"
                                    searchPageUrl={this.props.searchPageUrl}
                                />
                                }
                                {this.props.searchInputValue.length > 0 &&
                                    pathname.toString() !== this.props.searchPageUrl &&
                                this.renderSuggestions(false)
                                }
                                <button
                                    onClick={() => this.toggleNavMenu()}
                                    className="c_nav-toggle-button"
                                    aria-label={translate.App.Menu}
                                    aria-expanded={this.state.navMenuIsOpen}
                                    aria-controls="masterNavMenu"
                                >
                                    <span className="c_nav-toggle-button__text u_hidden--mobile">
                                        {translate.App.Menu}
                                    </span>
                                    {this.state.navMenuIsOpen ?
                                        <SvgElement
                                            src={this.getIcon('close')}
                                            title={translate.App.CloseMenu}
                                            desc={translate.App.ButtonToCloseMenu}
                                            className="c_nav-toggle-button__icon c_nav-toggle-button__icon--close-menu u_hidden--desktop"
                                        />
                                        :
                                        <SvgElement
                                            src={this.getIcon('hamburger')}
                                            title={translate.App.OpenMenu}
                                            desc={translate.App.ButtonToOpenMenu}
                                            className="c_nav-toggle-button__icon u_hidden--desktop"
                                        />
                                    }
                                    <SvgElement
                                        src={menuIconWhite}
                                        title={translate.App.OpenMenu}
                                        desc={translate.App.ButtonToOpenMenu}
                                        className="c_nav-toggle-button__icon u_hidden--mobile"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    {this.props.epiDataModelLoaded && this.props.epiDataModel.header &&
                    this.props.epiDataModel.header.menu && currentLevel &&
                        <NavMenu
                            isOpen={this.state.navMenuIsOpen}
                            closeMenu={this.toggleNavMenu}
                            location={this.props.location}
                            focusHandler={this.focusHandler}
                            currentLevel={currentLevel}
                        />
                    }
                    {this.state.mobileSearchIsOpen &&
                        <div
                            ref={this.mobileSearchPanelRef}
                            className={`c_mobile-search-panel u_hidden--desktop${this.state.closeMobileSearch ?
                                ' c_mobile-search-panel--close' : ''}`}
                        >
                            <button
                                className="c_close-button"
                                onClick={() => this.setMobileSearchPanelState(false)}
                                aria-expanded={this.state.mobileSearchIsOpen}
                            >
                                <span className="c_close-button__inner-wrapper">
                                    <span className="c_close-button__text">{translate.App.Close}</span>
                                    <SvgElement
                                        src={closeBlack}
                                        title={translate.App.CloseSearch}
                                        desc={translate.App.ButtonToCloseSearch}
                                    />
                                </span>
                            </button>
                            <HeaderSearchField
                                className="c_search-field--small-screen u_hidden--desktop"
                                id="mainMobileHeaderSearchInput"
                                searchPageUrl={this.props.searchPageUrl}
                                alwaysFullWidth
                            />
                            {this.props.searchInputValue.length > 0 &&
                            this.renderSuggestions(true)
                            }
                        </div>
                    }

                    {/* eslint-disable-next-line */}
                    <div
                        className="c_master-header__overlay"
                        style={{
                            height: `${this.state.navMenuIsOpen ? this.calculateBodyHeight() : 0}px`
                        }}
                        onClick={() => this.toggleNavMenu()}
                    />

                    {pathname.toString() !== '/' && window.innerWidth > 768 &&
                        this.props.epiDataModelLoaded && this.props.epiDataModel.header &&
                        <BreadCrumbs breadcrumbs={header.breadCrumbs} />
                    }
                </header>
            </Fragment>
        );
    }
}

Header.propTypes = {
    className: PropTypes.string.isRequired,
    location: PropTypes.object,
    searchInputValue: PropTypes.string.isRequired,
    searchSuggestions: PropTypes.array,
    language: PropTypes.object,
    epiDataModel: PropTypes.object,
    epiDataModelLoaded: PropTypes.bool,
    searchIsLoading: PropTypes.bool,
    searchPageUrl: PropTypes.string
};

const mapStateToProps = state => ({
    searchInputValue: state.siteSearch.inputValue,
    searchSuggestions: state.siteSearch.searchSuggestions,
    searchIsLoading: state.siteSearch.isLoading,
    language: state.app.language,
    epiDataModel: state.app.epiDataModel,
    epiDataModelLoaded: state.app.epiDataModelLoaded
});

export default connect(mapStateToProps, {})(Header);
